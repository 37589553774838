.StripeElement {
  min-height: rem(24px);
  flex: 1;
  background-color: transparent;
  color: var(--sleep-beige-c000);
  border-bottom: 1px solid var(--sleep-blue-c200);

  &__label {
    @include label;
  }

  &__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &--invalid {
    border-bottom-color: var(--sleep-red-c500);
  }
}
