@mixin bg-contain-center {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin image-luna-moon {
  min-height: rem(320px);
  margin-top: rem(-30px);
  @include bg-contain-center;
  background-image: url("../../img/luna-sleeping.svg");
}

@mixin article-image-position-b2b {
  display: none;
  @include respond-min($breakpoint-tablet) {
    display: block;
    background-position: bottom;
    margin-bottom: rem(35px);
  }
  @include respond-min($breakpoint-desktop) {
    background-position: top;
  }
}
