.terminate {
  &__input {
    @include input;
    flex: 0 0 auto;
    width: 100%;
    padding-left: calc(1.5rem * 0.5);
    padding-right: calc(1.5rem * 0.5);
    @include respond-min($breakpoint-tablet) {
      width: 75%;
    }
    &__select {
      background-image: url('../../img/terminate/nav-arrow-down.svg');
      background-position: right 1.25rem center;
      background-repeat: no-repeat;
      background-size: 21px 21px;
    }
  }

  &__input-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(1.5rem * -0.5);
    margin-right: calc(1.5rem * -0.5);
    margin-top: calc(0 * -1);
    margin-bottom: rem(25px);
    & > * {
      max-width: 100%;
      margin-left: calc(1.5rem * 0.5);
      margin-right: calc(1.5rem * 0.5);
      @include respond-min($breakpoint-tablet) {
        padding-left: calc(1.5rem * 0.5);
        padding-right: calc(1.5rem * 0.5);
        margin-left: 0;
        margin-right: 0;
        width: 75%;
      }
    }
  }

  &__error-message {
    @include error-message;
    align-self: flex-start;
    min-height: rem(24px);
    padding-top: rem(4px);
    @include respond-min($breakpoint-tablet) {
      margin-left: 25%;
    }
  }

  &__show-datepicker {
    width: 100%;
    @include respond-min($breakpoint-tablet) {
      width: 37%;
    }
  }

  &__btn--orange {
    @extend .link__cta;
    align-self: flex-end;
    align-self: center;
    padding-left: rem(80px);
    padding-right: rem(80px);
  }

  &__form {
    @include form;
    margin-bottom: rem(250px);
    display: flex;
    flex-direction: column;
    max-width: rem(940px);
  }

  &__label {
    @include label;
    font-weight: $font-weight-light;
    font-size: rem(18px);
    color: var(--sleep-beige-c100);
    margin-top: 0;
    margin-bottom: calc(1rem + 2px);
    display: inline-block;
    flex-shrink: 0;
    max-width: 100%;
    flex: 0 0 auto;
    width: 100%;
    @include respond-min($breakpoint-tablet) {
      margin-bottom: rem(5px);
      width: 25%;
      padding-left: calc(1.5rem * 0.5);
      padding-right: calc(1.5rem * 0.5);
    }
  }

  &__wrapper-lg-w50 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include respond-min($breakpoint-tablet) {
      flex-basis: 50%;
    }
  }

  &__wrapper-address {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  &__paragraph {
    color: var(--sleep-beige-c100);
    font-weight: $font-weight-light;
    margin-bottom: rem(16px);
    font-size: rem(14px);
    line-height: rem(23px);
    letter-spacing: 0.15px;
    text-align: center;
    @include font-smoothing;
    @include respond-min($breakpoint-tablet) {
      flex: 0 0 auto;
      width: 75%;
      margin-left: 25%;
    }
  }

  .datepicker {
    width: 100%;
    @include respond-min($breakpoint-tablet) {
      width: 37%;
      padding-top: 13px;
    }
    input {
      border: none;
      background-color: initial;
      border-bottom: 1px solid var(--sleep-blue-c200);
      color: var(--sleep-beige-c000);
      border-radius: 0;
    }
  }

  .rw-widget-picker {
    border: none;
    background-color: initial;
    color: var(--sleep-beige-c000);
  }

  .rw-picker-btn:hover {
    background-color: var(--sleep-blue-c200);
  }

  .rw-picker-caret,
  .rw-picker-btn {
    border: none;
    color: var(--sleep-beige-c000);
    border-bottom: 1px solid var(--sleep-blue-c200);
  }

  .imprint__image-freddy-laeuft {
    background-image: url('../../img/terminate/freddy-laeuft-terminate.svg');
    left: 50%;
    transform: translateX(-50%);
    min-height: rem(78px);
    width: 100%;
    max-width: rem(393px);
  }
}
