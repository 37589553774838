.loading-spinner {
  border: 3px solid var(--sleep-beige-c000);
  border-top: 3px solid var(--sleep-blue-c200);
  border-radius: 50%;
  width: rem(20px);
  height: rem(20px);
  animation: spin 2s linear infinite;
}

.loading-spinner__wrapper {
  flex: 1;
  display: flex;
  width: 7.1ch;
  align-items: center;
  justify-content: center;
  min-height: rem(16px);
  min-width: rem(16px);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
