.how-to-continue-desktop {
  .icons {
    width: rem(100px);
    height: rem(88px);
  }

  .resend-link {
    color: var(--sleep-beige-c100);
    text-decoration: underline !important;

    &:hover {
      color: var(--sleep-red-c500);
    }
  }
}
