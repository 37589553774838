.list {
  @extend .paragraph;
  list-style-type: none;

  &__item-checkmark {
    display: flex;
    flex-direction: row;
    padding: rem(4px) 0;
    align-items: center;
    max-width: 95%;
    @include respond-min($breakpoint-tablet) {
      padding: rem(6px) 0;
      max-width: 100%;
    }
    &::before {
      content: "" !important;
      background-image: url("../../img/check-circle.svg");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      min-height: rem(35px);
      min-width: rem(35px);
      margin-right: rem(30px);
    }
  }
  &__item-plus {
    display: flex;
    flex-direction: row;
    padding: rem(4px) 0;
    align-items: center;
    max-width: 95%;

    @include respond-min($breakpoint-tablet) {
      padding: rem(6px) 0;
      max-width: 100%;
    }

    &::before {
      content: "" !important;
      background-image: url("../../img/plus-circle.svg");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      min-height: rem(32px);
      min-width: rem(32px);
      margin-right: rem(30px);
    }
  }
}
