.link {
  &__cta {
    @include button-orange;
  }

  &__store-btn-wrapper {
    flex: 1;
    display: flex;
    margin-top: rem(24px);
    justify-content: center;
  }

  &__store-btn {
    width: rem(110px);
    height: rem(30px);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include on-interaction {
      opacity: 0.8;
    }
  }

  &__appstore {
    background-image: url('../../img/app-store-badge.svg');
  }
  &__playstore {
    background-image: url('../../img/google-play-badge.svg');
  }

  &__markdown {
    @include link;
    color: var(--sleep-red-c500);
  }
}
