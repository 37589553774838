.blockquote {
  font-weight: $font-weight-semibold;
  &__subline {
    font-weight: $font-weight-light;
    font-size: rem(16px);
    line-height: rem(24px);
  }
  &__wrapper {
    flex: 1;
    display: flex;
  }
  &__text-holder {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: rem(12px);
  }
  &__icon-speechbubble {
    flex: 1;
    background-repeat: no-repeat;
    max-width: rem(40px);
    background-position-y: rem(5px);
    background-size: contain;
    background-image: url(../../img/icon_speechbubble.svg);
  }

  &__markdown {
    @extend .box__rounded;
    padding: 1.875rem 0;
  }
}
