.subheader {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: rem(105px);
  margin-bottom: rem(16px);
  text-transform: uppercase;
  color: var(--sleep-beige-c000);
  font-family: $font-sans-pro;
  font-size: rem(18px);
  font-weight: $font-weight-semibold;
  justify-content: flex-start;
  letter-spacing: 0.04rem;
  @include respond-max($breakpoint-phablet - rem(1px)) {
    justify-content: center;
  }
  @include font-smoothing;
  &__link {
    opacity: 0.7;
    white-space: nowrap;
    margin-bottom: rem(12px);
    &:not(:first-child) {
      &::before {
        content: '|';
        display: inline-block;
        margin: 0 rem(8px);
        opacity: 0.7;
      }
    }
    &--active {
      opacity: 1;
      text-decoration: underline;
    }
  }

  &__wrapper {
    display: flex;
    width: 100%;
    max-width: rem(940px);
    @include respond-max($breakpoint-tablet) {
      max-width: 42rem;
    }
  }
}
