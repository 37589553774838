@font-face {
  font-family: "Calluna";
  src: url("../fonts/Calluna-Regular.woff2") format("woff2"),
    url("../fonts/Calluna-Regular.woff") format("woff"),
    url("../fonts/Calluna-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calluna";
  src: url("../fonts/Calluna-Bold.woff2") format("woff2"),
    url("../fonts/Calluna-Bold.woff") format("woff"),
    url("../fonts/Calluna-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Bold.woff2") format("woff2"),
    url("../fonts/SourceSansPro-Bold.woff") format("woff"),
    url("../fonts/SourceSansPro-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Light.woff2") format("woff2"),
    url("../fonts/SourceSansPro-Light.woff") format("woff"),
    url("../fonts/SourceSansPro-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Regular.woff2") format("woff2"),
    url("../fonts/SourceSansPro-Regular.woff") format("woff"),
    url("../fonts/SourceSansPro-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-SemiBold.woff2") format("woff2"),
    url("../fonts/SourceSansPro-SemiBold.woff") format("woff"),
    url("../fonts/SourceSansPro-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-LightItalic.woff2") format("woff2"),
    url("../fonts/SourceSansPro-LightItalic.woff") format("woff"),
    url("../fonts/SourceSansPro-LightItalic.ttf") format("ttf");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
