.imprint {
  &__markdown-wrapper{
    padding-bottom: 5rem;
  }
  &__image-freddy-laeuft {
    background-image: url("../../img/freddy-laeuft.svg");
    height: auto;
    min-height: rem(114px);
    width: 100%;
    max-width: rem(600px);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    bottom: rem(-27px);

    @include respond-min($breakpoint-tablet) {
      bottom: rem(-18px);
    }
  }
}
