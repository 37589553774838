.icon-box {
  display: flex;
  margin: rem(40px) 0;
  padding: 0;
  align-items: center;
  @include respond-min($breakpoint-tablet) {
    max-width: 50rem;
  }
  @include respond-min($breakpoint-desktop) {
    max-width: 65rem;
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 110px;
    background-repeat: no-repeat;
    background-position: top center;
    margin-bottom: rem(15px);
    @include respond-min($breakpoint-tablet) {
      width: 8rem;
      height: 80px;
      background-size: contain;
    }
    @include respond-min($breakpoint-desktop) {
      height: 110px;
      background-size: contain;
    }
  }

  &__wrapper {
    @include respond-min($breakpoint-tablet) {
      max-width: 12.5rem;
    }
    @include respond-min($breakpoint-desktop) {
      max-width: 16rem;
    }
  }

  &__paragraph {
    @include paragraph;
    padding: 0 rem(16px) rem(32px);
    width: 80%;

    @include respond-min($breakpoint-tablet) {
      width: 100%;
      padding: 0 rem(8px) rem(32px);
    }
    @include respond-min($breakpoint-desktop) {
      width: 100%;
    }

    &-additional {
      @include paragraph;
      margin-bottom: 0;
    }
  }

  &__bottom-spacer {
    height: rem(36px);
  }
}
