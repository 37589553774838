.intro_b2b {
  padding-bottom: rem(40px);
  @include respond-min($breakpoint-tablet) {
    padding-bottom: rem(70px);
  }

  &__section {
    @include flex-col;
    flex-basis: 100%;
    margin-bottom: rem(30px);
    @include respond-min($breakpoint-tablet) {
      flex-direction: row;
      align-items: center;
      margin-bottom: rem(50px);
    }
  }

  &__bgr-stars {
    @extend .home__bgr-top-section;
  }

  &__cta-container {
    @include flex-1-col-start-center;

    @include respond-min($breakpoint-tablet) {
      flex-basis: 50%;
      padding-top: rem(100px);
    }

    @include respond-min($breakpoint-desktop) {
      flex-basis: 65%;
    }
  }

  &__link-cta {
    @include button-orange;
    min-width: rem(300px);
    align-self: center;

    @include respond-min($breakpoint-tablet) {
      max-width: rem(400px);
    }
  }

  &__paragraph {
    @extend .paragraph;
    align-self: center;
    text-align: center;
    max-width: rem(300px);
    margin: rem(20px) 0 rem(30px);

    @include respond-min($breakpoint-tablet) {
      max-width: rem(350px);
    }

    @include respond-min($breakpoint-desktop) {
      flex-basis: 65%;
      max-width: rem(500px);
    }
  }

  &__hero-image-xing-premium {
    @include image-luna-moon;

    @include respond-min($breakpoint-tablet) {
      flex-basis: 50%;
      min-height: rem(418px);
    }
    @include respond-min($breakpoint-desktop) {
      flex-basis: 35%;
    }
  }

  &__hero-image-xing-pro-jobs {
    min-height: rem(220px);
    max-height: rem(320px);
    margin-top: rem(60px);
    margin-bottom: rem(30px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../img/luna_writing_on_moon.svg");

    @include respond-min($breakpoint-tablet) {
      flex-basis: 30%;
      margin-top: rem(90px);
      height: rem(280px);
      margin-left: rem(80px);
    }
    @include respond-min($breakpoint-desktop) {
      flex-basis: 30%;
      margin-top: rem(140px);
      margin-left: 0;
    }
  }

  &__hero-image-zpp {

  }
}
