.badge {
  width: calc(100% + 1.9rem);
  bottom: 2rem;
  left: 0;
  background-color: #e06f4c;
  position: relative;
  border-top-left-radius: rem(25px);
  border-top-right-radius: rem(25px);
  color: #f0ead5;
  padding: 0.5rem 0;
  font-family: $font-sans-pro;
  font-weight: $font-weight-normal;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;

  @include respond-min($breakpoint-desktop-large) {
    width: calc(100% + 3.2rem);
    bottom: 1rem;
  }
  @include respond-max($breakpoint-phone-xs) {
    width: calc(100% + 0.1rem);
  }
}
