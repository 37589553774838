.padding-top-150 {
  padding-top: rem(150px);
}

.padding-top-100 {
  padding-top: rem(100px);
}

.padding-top-30-100 {
  padding-top: rem(30px);
  @include respond-min($breakpoint-desktop) {
    padding-top: rem(100px);
  }
}

.padding-top-25 {
  padding-top: rem(25px);
}

.padding-top-15 {
  padding-top: rem(15px);
}

.padding-bottom-100 {
  padding-bottom: rem(100px);
}

.padding-bottom-25 {
  padding-bottom: rem(25px);
}
.padding-bottom-15 {
  padding-bottom: rem(15px);
}

.padding-16 {
  padding: rem(16px);
}

.padding-top-lg--150 {
  @include respond-min($breakpoint-desktop) {
    padding-top: rem(150px);
  }
}

.padding-l-0 {
  padding-left: 0;
}

.padding-r-0 {
  padding-right: 0;
}

.padding-l-10 {
  padding-left: rem(10px);
}

.padding-r-10 {
  padding-right: rem(10px);
}

.padding-l-15 {
  padding-left: rem(15px);
}

.padding-r-15 {
  padding-right: rem(15px);
}

.padding-r-0-30 {
  padding-right: 0;
  @include respond-min($breakpoint-tablet) {
    padding-right: rem(30px);
  }
}

.padding-l-0-30 {
  padding-left: 0;
  @include respond-min($breakpoint-tablet) {
    padding-left: rem(30px);
  }
}

.padding-l-0-100 {
  padding-left: 0;
  @include respond-min($breakpoint-tablet) {
    padding-left: rem(100px);
  }
}

.padding-r-0-100 {
  padding-right: 0;
  @include respond-min($breakpoint-tablet) {
    padding-right: rem(100px);
  }
}

.padding-30-50 {
  padding: rem(30px);
  @include respond-min($breakpoint-tablet) {
    padding: rem(50px);
  }
}

.padding-top-50-100 {
  padding-top: rem(50px);
  @include respond-min($breakpoint-tablet) {
    padding-top: rem(100px);
  }
}
