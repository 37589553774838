.heading {
  font-family: $font-calluna;
  font-weight: $font-weight-normal;
  font-variant: lining-nums;
  color: var(--sleep-beige-c100);
  text-align: center;
  @include font-smoothing;
}

.heading-1 {
  font-size: rem(45px);
  margin-bottom: rem(40px);
  @include respond-min($breakpoint-desktop) {
    font-size: rem(60px);
  }
}

.heading-1-b2b {
  font-size: rem(40px);
  line-height: rem(46px);
  @include respond-min($breakpoint-desktop) {
    font-size: rem(53px);
    line-height: rem(60px);
  }
}

.heading-2 {
  font-size: rem(36px);
  margin-bottom: rem(20px);
  &__margin-bottom-0 {
    margin-bottom: 0;
  }
  &--highlight {
    font-size: rem(45px);
  }
}

.heading-2-b2b {
  font-size: rem(28px);
  line-height: rem(35px);
  margin-bottom: rem(20px);
  &__margin-bottom-0 {
    margin-bottom: 0;
  }
  @include respond-min($breakpoint-tablet) {
    font-size: rem(36px);
    line-height: rem(43px);
  }
}

.heading-3 {
  font-size: rem(26px);
  text-align: left;
  font-weight: $font-weight-bold;
  margin-bottom: rem(8px);
}

.heading-4 {
  font-family: $font-sans-pro;
  font-weight: $font-weight-semibold;
  font-size: rem(24px);
  text-align: left;
  margin-bottom: rem(4px);
  @include respond-min($breakpoint-tablet) {
    font-size: rem(20px);
  }
}

.heading-5 {
  flex: 1;
  margin: rem(20px);
  font-family: $font-sans-pro;
  font-weight: $font-weight-semibold;
  font-size: rem(18px);
}
