// default font declarations
$font-sans-pro: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
  sans-serif;
$font-calluna: "Calluna", "Georgia", serif;
$base-font-size: 16px;
$font-weight-light: 300;
$font-weight-normal: normal;
$font-weight-semibold: 600;
$font-weight-bold: bold;
$font-style-italic: italic;

// semantic fonts
$font-standard: $font-sans-pro;

// breakpoints
$breakpoint-phone-xs: rem(325px);
$breakpoint-phablet: rem(480px);
$breakpoint-tablet: rem(768px);
$breakpoint-desktop: rem(968px);
$breakpoint-desktop-large: rem(1200px);
$breakpoint-desktop-x-large: rem(1800px);

// hamburger menue
$hamburger-layer-color: #ece3c4;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: 1;
$hamburger-hover-opacity: 1;
$hamburger-layer-width: rem(32px);
$hamburger-layer-height: 3px;
$hamburger-padding-y: 0;

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: rem(16px);
}
