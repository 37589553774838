.article {
  @include flex-1-col;
  grid-column: span 12;
  @include respond-min($breakpoint-tablet) {
    grid-column: span 6;
  }
  @include respond-min($breakpoint-desktop) {
    grid-column: span 3;
    padding: rem(8px);
  }
  &__image {
    min-height: rem(263px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-bottom: rem(20px);

    @include respond-min($breakpoint-desktop) {
      background-position: unset;
      margin: 0 rem(-8px);
    }

    &--einschlafen {
      background-image: url('../../img/illu_einschlafen.svg');
      @include respond-min($breakpoint-tablet) {
        background-position: right;
        background-position-y: rem(-10px);
      }
      @include respond-min($breakpoint-desktop) {
        background-position: right;
        background-position-y: 0;
      }
    }
    &--verstehen {
      background-image: url('../../img/illu_verstehen.svg');

      @include respond-min($breakpoint-tablet) {
        background-position-y: rem(10px);
        background-position: left;
      }

      @include respond-min($breakpoint-desktop) {
        background-position-y: rem(7px);
      }
    }
    &--tracken {
      background-image: url('../../img/illu_tracken.svg');

      @include respond-min($breakpoint-tablet) {
        background-position-y: rem(10px);
        background-position: right;
      }
      @include respond-min($breakpoint-desktop) {
        background-position-y: rem(10px);
      }
    }
    &--verankern {
      background-image: url('../../img/illu_verankern.svg');
      background-size: 95%;
      background-position: left top;
      @include respond-min($breakpoint-tablet) {
        background-size: 90%;
        background-position: left;
        background-position-y: rem(-6px);
      }
      @include respond-min($breakpoint-desktop) {
        background-position: bottom;
        background-position-y: rem(-2px);
        background-size: 100%;
        margin: 0 0 0 rem(-8px);
      }
    }

    &--dein-code-mobile {
      background-image: url('../../img/illu_dein_code_mobile.svg');
      @include respond-min($breakpoint-tablet) {
        display: none;
      }
    }

    &--dein-code {
      background-image: url('../../img/illu_dein_code.svg');
      @include article-image-position-b2b;
    }

    &--code-einloesen {
      background-image: url('../../img/illu_code_einloesen.svg');
      @include article-image-position-b2b;
    }

    &--besser-schlafen {
      background-image: url('../../img/illu_besser_schlafen.svg');
      @include article-image-position-b2b;
    }
  }

  &__content__wrapper {
    @include respond-min($breakpoint-tablet) {
      padding-right: rem(16px) !important;
    }
    @include respond-min($breakpoint-desktop) {
      padding: 0 rem(8px);
    }
  }

  &__wrapper {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    @include grid-template(repeat(12, 1fr), 0, rem(24px));
    @include respond-min($breakpoint-desktop) {
      column-gap: 0;
    }
  }

  &__wrapper-b2b {
    @include flex-1-col;
    flex-wrap: wrap;
    align-self: center;

    @include respond-min($breakpoint-tablet) {
      flex-direction: row;
      column-gap: 0;
      margin-top: rem(-70px);
    }
    @include respond-min($breakpoint-desktop) {
      margin-top: 0;
    }
  }

  &__paragraph {
    @include paragraph;
    max-width: 70%;
    align-self: center;
    margin: 0 0 rem(30px);
    @include respond-min($breakpoint-tablet) {
      max-width: 90%;
      margin-top: rem(8px);
    }

    a {
      text-decoration: underline;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
