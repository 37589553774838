.paragraph {
  color: var(--sleep-beige-c100);
  font-weight: $font-weight-light;
  margin-bottom: rem(16px);
  font-size: rem(17px);
  line-height: rem(23px);
  letter-spacing: 0.15px;
  @include font-smoothing;

  @include respond-min($breakpoint-desktop) {
    font-size: rem(18px);
    line-height: rem(23px);
  }

  &__text-center {
    text-align: center;
  }
  &__monthly {
    font-family: $font-sans-pro;
    font-weight: normal;
  }
  &__hint {
    font-family: $font-sans-pro;
    font-weight: lighter;
    font-size: 0.8rem;
  }
}
