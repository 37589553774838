@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin flex-1-col {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@mixin flex-1-col-center-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin flex-1-col-start-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@mixin flex-1-col-center-start {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-1-row {
  flex: 1;
  display: flex;
  flex-direction: row;
}
