@mixin respond-min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin respond-max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin on-interaction {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@mixin visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin responsive-background-image($name, $suffix) {
  background-image: url(../../img/#{$name}@2x.#{$suffix});
  background-image: -webkit-image-set(
    url(../../img/#{$name}.#{$suffix}) 1x,
    url(../../img/#{$name}@2x.#{$suffix}) 2x,
    url(../../img/#{$name}@3x.#{$suffix}) 3x
  );
  background-image: image-set(
    url(../../img/#{$name}.#{$suffix}) 1x,
    url(../../img/#{$name}@2x.#{$suffix}) 2x,
    url(../../img/#{$name}@3x.#{$suffix}) 3x
  );
}

@mixin grid-template($columns, $colgap, $rowgap) {
  display: grid;
  grid-template-columns: $columns;
  column-gap: $colgap;
  row-gap: $rowgap;
}

@mixin padding-in-rounded-box {
  padding: rem(10px);
  @include respond-min($breakpoint-tablet) {
    padding: rem(30px) rem(50px);
  }
}
