@mixin wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: rem(1088px);
  width: 100%;
  padding: 0 rem(20px);
  &__flex-col-row {
    flex: 1;
    display: flex;
    flex-direction: column;
    @include respond-min($breakpoint-tablet) {
      flex-direction: row;
    }
  }
  &__flex-row {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
  &__flex-col {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__min-height {
    @include respond-min($breakpoint-phone-xs) {
      min-height: 690px;
    }
  }
}

@mixin wrapper-input {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: rem(10px);
  width: 100%;

  @include respond-min($breakpoint-tablet) {
    align-items: unset;
    flex-flow: row nowrap;
  }
}
