@mixin input {
  border-bottom: 1px solid var(--sleep-blue-c200);
  color: var(--sleep-beige-c000);
  font-size: rem(18px);

  &--error,
  &--error:focus,
  &:invalid,
  &:invalid:focus {
    border-bottom: 1px solid var(--sleep-red-c300);
  }

  &:not(&--error) {
    &:focus {
      border-bottom: 1px solid var(--sleep-blue-c200);
    }
  }

  &::placeholder {
    color: var(--sleep-blue-c1000);
  }

  &:focus {
    background-color: var(--sleep-blue-c770);
  }

  @include font-smoothing;
  display: block;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @include input;
  -webkit-text-fill-color: var(--sleep-beige-c000) !important;
  -webkit-box-shadow: 0 0 0 30px var(--sleep-blue-c900)-transparent inset !important;
}
