* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul {
  padding: 0;

  li {
    list-style: none;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
figure,
p,
ul,
ol,
blockquote {
  margin: 0;
  padding: 0;
}

ol {
  padding-left: rem(24px);
}

button,
fieldset,
input,
legend,
select,
textarea {
  border-radius: 0;
  font-family: inherit;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  appearance: none;
  &:focus {
    border: none;
    outline: none;
  }
}
