.summary {
  color: var(--sleep-beige-c000);
  &__heading {
    @extend .heading-5;
    text-align: left;
    margin: 0;
    flex: unset;
  }

  &__section {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: rem(30px);
    flex-basis: 100%;
    @include respond-min($breakpoint-tablet) {
      flex-basis: 47%;
      max-width: rem(400px);
    }
  }

  &__line-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__label-offer {
    text-transform: uppercase;
    color: var(--sleep-red-c500);
    font-weight: $font-weight-semibold;
    margin-bottom: rem(16px);
  }

  &__label-total {
    color: var(--sleep-red-c500);
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }

  &__price-total {
    font-weight: $font-weight-semibold;
    color: var(--sleep-red-c500);
  }

  &__label-email {
    font-weight: $font-weight-semibold;
    margin-top: rem(25px);
  }

  &__email {
    font-weight: $font-weight-semibold;
    color: var(--sleep-red-c500);
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    @include box-rounded;
    width: 100%;
    max-width: rem(940px);
    margin-bottom: rem(250px);
    padding: rem(32px);
    @include respond-min($breakpoint-tablet) {
      margin-bottom: rem(160px);
    }

    @include respond-max($breakpoint-tablet) {
      max-width: 42rem;
    }
  }

  &__wrapper-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__paragraph {
    @include paragraph;
    font-weight: $font-weight-normal;
    margin-top: rem(20px);
    margin-bottom: 0;
    margin-right: auto;
  }

  &__link {
    color: var(--sleep-red-c500);
    text-decoration: underline;
    @include on-interaction {
      text-decoration: none;
    }
  }

  &__link-edit {
    position: absolute;
    right: 0;
    background-image: url('../../img/icon_edit.svg');
    width: rem(20px);
    height: rem(20px);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__btn--orange {
    @include button-orange-in-form;
    padding: rem(15px) rem(50px);
  }

  &__btn--blue {
    @include button-blue-in-form;
    padding: rem(15px) rem(50px);
  }

  &__btn-wrapper {
    @include button-wrapper;
  }
}
