.confirmation {
  .freddy-sleep {
    position: absolute;
    bottom: -25px;
  }
  &__wrapper {
    @include respond-min($breakpoint-desktop) {
      width: 42rem;
    }
  }
  .text-underline {
    text-decoration: underline;
  }
}
