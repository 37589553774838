.home {
  &__section-1 {
    @include flex-col;
    flex-basis: 100%;
    margin-bottom: rem(140px);
    @include respond-min($breakpoint-tablet) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__section-2 {
    @include flex-1-col;
    padding-bottom: rem(20px);
  }

  &__section-3 {
    @include flex-1-col;
    padding-top: rem(30px);
    margin-bottom: rem(40px);
    @include respond-min($breakpoint-tablet) {
      margin-bottom: rem(80px);
    }
  }

  &__section-4 {
    @include flex-1-col;
    padding: 0;
    align-items: center;
  }

  &__image-luna-moon {
    @include image-luna-moon;
    @include respond-min($breakpoint-tablet) {
      flex: 1;
      background-size: cover;
      min-height: rem(418px);
    }
  }

  &__image-portrait-albrecht {
    min-height: rem(280px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: rem(20px) rem(20px) rem(30px);
    @include respond-min($breakpoint-tablet) {
      min-width: rem(280px);
      background-position: top;
    }
    @include responsive-background-image('portrait_albrecht', 'png');
  }

  &__image-phone {
    margin: 0 auto;
    background-repeat: no-repeat;
    height: rem(360px);
    width: rem(300px);
    background-position: center;
    background-size: contain;

    @include respond-min($breakpoint-tablet) {
      height: rem(360px);
      width: rem(302px);
      background-position: unset;
    }

    &--left {
      background-image: url('../../img/phone-left.png');
    }

    &--right {
      background-image: url('../../img/phone-right.png');
      display: none;
      margin-left: rem(20px);
      @include respond-min($breakpoint-tablet) {
        display: block;
      }
    }
  }

  &__cta-container {
    @include flex-1-col-center-center;

    .cta-button {
      margin-bottom: 0;
    }
  }

  &__bgr-top-section {
    @include flex-1-col-center-center;
    background-size: cover;
    background-position: center;
    width: 100%;
    background-image: url('../../img/stars.svg');
  }
}
