@mixin scrollbar($size: 3px) {
  scrollbar-width: thin !important;
  scrollbar-color: var(--sleep-blue-c600) !important;

  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: 0.625rem !important;
    border-radius: 0.75rem !important;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--sleep-blue-c600) !important;
    border-radius: 0.75rem !important;
  }
  
  &::-webkit-scrollbar-track {
    background: var(--sleep-blue-c800) !important;
    border-radius: 0.75rem !important;
  }
}
