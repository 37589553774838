.explanation-code {
  &__section {
    @extend .home__section-3;
  }

  &__heading-2-b2b {
    @extend .heading-2-b2b;
    margin: rem(10px) rem(30px);
    @include respond-min($breakpoint-tablet) {
      margin: rem(30px) rem(30px);
    }
  }
}
