body {
  font-family: 'Source Sans Pro', sans-serif;
  display: block;
  width: 100%;
  @include respond-min($breakpoint-tablet) {
    display: block;
  }
}

.visuallyhidden {
  @include visuallyhidden;
}

.em {
  font-weight: $font-weight-normal;
  display: inline;
}

.center {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.margin-bottom-48 {
  margin-bottom: rem(48px) !important;
}

.ant-select-dropdown .ant-select-item {
  font-family: 'Source Sans Pro';
  color: #f0ead5;
  font-weight: 300;
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  letter-spacing: 0.15px;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #041a3e51;
  color: var(--sleep-beige-c100) !important;
}

.ant-select-item.ant-select-item-option-grouped:hover {
  background-color: var(--sleep-blue-c200) !important;
}

.ant-select-selection-placeholder {
  font-weight: 500 !important;
  letter-spacing: 1px !important;
}
