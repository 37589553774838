%button {
  max-width: 100%;
  color: var(--sleep-beige-c100);
  text-transform: uppercase;
  font-size: rem(16px);
  font-weight: 600;
  letter-spacing: rem(0.9px);
  padding: rem(15px) rem(40px);
  border-radius: 50px;
  white-space: nowrap;
  text-align: center;
  transition: box-shadow 0.1s ease-in-out;
  @include respond-min($breakpoint-phablet) {
    padding-left: rem(100px);
    padding-right: rem(100px);
  }

  @include font-smoothing;
}

%button-in-form {
  margin-bottom: rem(30px);
  padding-left: rem(100px);
  padding-right: rem(100px);
  @include respond-min($breakpoint-phone-xs) {
    padding: rem(15px) rem(40px);
  }
  @include respond-min($breakpoint-phablet) {
    margin: 0;
  }
  @include respond-min($breakpoint-tablet) {
    padding-left: rem(100px);
    padding-right: rem(100px);
  }
}

@mixin button-orange {
  @extend %button;
  background-image: linear-gradient(to right, var(--sleep-red-c500), var(--sleep-red-c800));
  background-color: var(--sleep-red-c800);
  @include on-interaction {
    &:not(:disabled) {
      box-shadow: inset 0 0 0 1px var(--sleep-beige-c200);
      background-image: linear-gradient(to left, var(--sleep-red-c500), var(--sleep-red-c800));
    }
  }
}

@mixin button-blue {
  @extend %button;
  background-image: linear-gradient(to left, var(--sleep-blue-c200), var(--sleep-blue-c600));
  @include on-interaction {
    &:not(:disabled) {
      box-shadow: inset 0 0 0 1px var(--sleep-beige-c200);
      background-image: linear-gradient(to left, var(--sleep-blue-c200), var(--sleep-blue-c600));
    }
  }
}

@mixin button-blue-in-form {
  @include button-blue;
  @extend %button-in-form;
}

@mixin button-orange-in-form {
  @include button-orange;
  @extend %button-in-form;
}

@mixin button-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  margin-top: rem(50px);
  justify-content: center;
  @include respond-min($breakpoint-phone-xs) {
    justify-content: space-around;
  }
  @include respond-min($breakpoint-phablet) {
    justify-content: space-between;
  }
}
