@mixin paragraph {
  color: var(--sleep-beige-c100);
  font-weight: $font-weight-light;
  margin-bottom: rem(16px);
  font-size: rem(17px);
  line-height: rem(23px);
  letter-spacing: 0.15px;
  @include font-smoothing;

  @include respond-min($breakpoint-desktop) {
    font-size: rem(19px);
    line-height: rem(26px);
  }
}
