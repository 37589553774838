.benefits-pro-jobs {
  &__section {
    @include flex-1-col;
    padding: rem(30px) rem(25px) 0;
    margin-bottom: 0;

    @include respond-min($breakpoint-tablet) {
      margin-bottom: -1rem;
    }

    @include respond-min($breakpoint-desktop) {
      max-width: 68rem;
      margin-bottom: 0;
    }
  }

  &__image-app-screens {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url("../../../img/iPhone_Screens_PJ.svg");
    background-size: 100%;
    width: 106%;
    min-height: 100px;
    height: calc(100vw * 1007 / 798);
    max-height: 1007px;
    max-width: 798px;
    margin: 0 auto;
    margin-top: 2rem;

    @include respond-min($breakpoint-tablet) {
      background-position: top center;
      min-height: 40rem;
      max-height: 43rem;
      margin-top: 0;
      width: 100%;
    }

    @include respond-min($breakpoint-desktop) {
      margin-top: 0;
    }
  }

  &__info-box {
    margin-top: rem(-50px);

    @include respond-min($breakpoint-tablet) {
      margin-top: rem(-195px);
      padding-right: rem(75px);
    }
    @include respond-min($breakpoint-desktop) {
      margin-top: rem(-135px);
      padding-right: 0;
    }
  }
}
