.payment-method {
  &__form {
    @include form;
    padding: rem(32px);
    margin-bottom: rem(250px);
  }
  &__radio-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: rem(32px);
  }

  &__label-radio {
    background-repeat: no-repeat;
    color: var(--sleep-beige-c000);
    &:first-of-type {
      margin-right: rem(32px);
    }
    &--active {
      text-decoration: underline;
    }
  }

  &__input {
    @include input;
  }

  &__input-radio {
    background-repeat: no-repeat;
    color: var(--sleep-beige-c000);
    background-image: url('../../img/radio_btn_default.svg');
    margin-right: rem(8px);
    width: rem(18px);
    height: rem(18px);
    &:checked {
      background-image: url('../../img/radio_btn_selected.svg');
    }
  }

  &__wrapper {
    width: 100%;
    max-width: rem(940px);
    flex: 1;
    @include respond-max($breakpoint-tablet) {
      max-width: 42rem;
    }
  }

  &__stripe-wrapper {
    display: flex;
    flex-direction: column;
    @include respond-min($breakpoint-tablet) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__btn--orange {
    @include button-orange-in-form;
  }

  &__btn--blue {
    @include button-blue-in-form;
  }

  &__btn-wrapper {
    @include button-wrapper;
  }

  &__invalid-feedback {
    color: var(--sleep-red-c500);
    flex-basis: 100%;
    margin-top: rem(16px);
  }
}
