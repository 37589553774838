@mixin coupon_field {
  flex: 1 1 100%;
  padding: rem(14px) rem(16px) rem(16px) rem(14px);
  text-align: center;
  border: solid 1px #4e72b0;
  border-radius: rem(50px);
  margin-bottom: 1rem;
  max-width: 58.75rem;
  width: 100%;
  color: var(--sleep-beige-c100);
  @include respond-min($breakpoint-tablet) {
    padding: rem(17px) rem(254px) rem(16px) rem(16px);
    text-align: unset;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
    border-right: 0;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--sleep-beige-c100);
    opacity: 1; /* Firefox */
    text-transform: uppercase;
    font-family: $font-sans-pro;
    font-size: rem(16px);
    font-weight: 300;
    letter-spacing: 1.3px;
  }

  &::-ms-input-placeholder,
  &:-ms-input-placeholder {
    /* Microsoft */
    color: var(--sleep-beige-c100);
    text-transform: uppercase;
    font-family: $font-sans-pro;
    font-size: rem(16px);
    font-weight: 300;
    letter-spacing: 1.3px;
  }
}
