.flex {
  &-column {
    flex-direction: column;
  }
  &-row {
    flex-direction: row;
  }

  &-basis-100 {
    flex-basis: 100%;
  }

  &-basis-75 {
    flex-basis: 75%;
  }

  &-basis-50 {
    flex-basis: 50%;
  }

  &-col-row {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    @include respond-min($breakpoint-tablet) {
      flex-direction: row;
    }
  }
}
