.align {
  &-self-normal {
    align-self: normal;
  }

  &-self-flex-start {
    align-self: flex-start;
  }

  &-self-flex-end {
    align-self: flex-end;
  }

  &-self-center {
    align-self: center;
  }

  &-items-center {
    align-items: center;
  }
}
