.markdown {
  &__div {
    width: 100%;
    align-self: flex-start;
    overflow: hidden;
  }

  &__list {
    @extend .paragraph;
    &::marker {
      font-size: inherit;
      font-family: inherit;
      font-weight: bold;
    }
  }
}
