.billing {
  &__input {
    @include input;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 rem(16px);

    &--city {
      @include respond-min($breakpoint-tablet) {
        width: 70%;
      }
    }
    &--zip-code {
      @include respond-min($breakpoint-tablet) {
        width: 30%;
      }
    }
  }

  &__error-message {
    @include error-message;
    align-self: flex-start;
    min-height: rem(24px);
    padding-top: rem(4px);
  }

  &__btn--orange {
    @extend .link__cta;
    align-self: flex-end;
    align-self: center;
    padding-left: rem(80px);
    padding-right: rem(80px);
  }

  &__form {
    @include form;
    margin-bottom: rem(250px);
    display: flex;
    flex-direction: column;
    max-width: rem(940px);
    @include respond-min($breakpoint-tablet) {
      flex-direction: row;
    }
  }

  &__label {
    @include label;
    margin-top: 0;
    margin-bottom: rem(5px);
  }

  &__wrapper-lg-w50 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include respond-min($breakpoint-tablet) {
      flex-basis: 50%;
    }
  }

  &__wrapper-address {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  &__paragraph {
    @include paragraph;
    margin-bottom: rem(32px);
  }

  &__image-luna,
  &__summary-image-luna {
    position: absolute;
    bottom: 0;
    right: 0;
    height: rem(250px);
    width: rem(250px);
    background-image: url("../../img/luna_boat.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    @include respond-min($breakpoint-desktop) {
      height: rem(256px);
      width: rem(400px);
    }
    @include respond-min($breakpoint-desktop-large) {
      height: rem(450px);
      width: rem(450px);
    }
    @include respond-min($breakpoint-desktop-x-large) {
      height: rem(800px);
      width: rem(800px);
    }
    @include respond-max($breakpoint-desktop) {
      right: 50%;
      transform: translateX(50%);
    }
  }
  &__summary-image-luna {
    @media (width: 1024px) and (orientation: landscape) {
      height: rem(170px);
      width: rem(272px);
    }
  }
}
