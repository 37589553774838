.contact {
  .freddy-show {
    width: 210px;
    height: auto;
    transform: translateY(rem(-15px));
    @include respond-min($breakpoint-phablet) {
      width: 228px;
      height: 160px;
      top: 36rem;
    }
  }
  &__wrapper-illu {
    justify-content: flex-start;
  }
}