.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-top-75 {
  margin-top: rem(75px);
}

.margin-bottom-75 {
  margin-bottom: rem(75px);
}

.margin-top-50 {
  margin-top: rem(50px);
}

.margin-bottom-50 {
  margin-bottom: rem(50px);
}

.margin-top-24 {
  margin-top: rem(24px);
}

.margin-bottom-24 {
  margin-bottom: rem(24px);
}

.margin-top-16 {
  margin-top: rem(16px);
}

.margin-bottom-16 {
  margin-bottom: rem(16px);
}

.margin-top-8 {
  margin-top: rem(8px);
}

.margin-bottom-8 {
  margin-bottom: rem(8px);
}

.margin-top-33 {
  margin-top: rem(33px);
}

.margin-bottom-33 {
  margin-bottom: rem(33px);
}

.margin-top-120 {
  margin-top: rem(120px);
}

.margin-bottom-100 {
  margin-bottom: rem(100px);
}

.margin-bottom-30-40 {
  margin-bottom: rem(30px);
  @include respond-min($breakpoint-tablet) {
    margin-bottom: rem(40px);
  }
}

.margin-bottom-20-40 {
  margin-bottom: rem(20px);
  @include respond-min($breakpoint-tablet) {
    margin-bottom: rem(40px);
  }
}

.margin-bottom-10-20 {
  margin-bottom: rem(10px);
  @include respond-min($breakpoint-tablet) {
    margin-bottom: rem(20px);
  }
}
