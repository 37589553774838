.display {
  &--mobile {
    @include respond-min($breakpoint-tablet) {
      display: none;
    }
  }
  &--desktop {
    display: none;
    @include respond-min($breakpoint-tablet) {
      display: inherit;
    }
  }
}

.flex {
  display: flex;
}
