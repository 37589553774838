.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: rem(15px);
  margin-top: rem(15px);
  background-color: var(--sleep-blue-c900)-transparent;
  min-height: rem(310px);
  &__rounded {
    border-radius: rem(5px);
  }

  &__rounded--dark-blue {
    border-radius: 0;
    border-bottom-right-radius: rem(5px);
    border-bottom-left-radius: rem(5px);
    background-color: var(--sleep-blue-c790);
    margin-bottom: -15px;
    width: calc(100% + rem(30px));
    padding: rem(40px) rem(50px);
    justify-content: center;
    align-items: center;
  }

  &__elem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &__payment {
    border-bottom: rem(5px) solid var(--sleep-blue-c600);
    border-bottom-right-radius: rem(25px);
    border-bottom-left-radius: rem(25px);
    border-top-left-radius: rem(16px);
    border-top-right-radius: rem(16px);

    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    max-width: 58.75rem;
    width: 100%;
    height: 100%;
    min-height: 16rem;
    @include respond-max($breakpoint-phone-xs) {
      padding: 0;
    }
    @include respond-min($breakpoint-tablet) {
      width: unset;
    }
    &:nth-child(2) {
      @include respond-min($breakpoint-desktop) {
        margin-left: rem(20px);
        margin-right: rem(20px);
      }
    }

    &:hover {
      border-top: 1px solid var(--sleep-blue-c200);
      border-left: 1px solid var(--sleep-blue-c200);
      border-right: 1px solid var(--sleep-blue-c200);
      border-bottom: rem(5px) solid var(--sleep-blue-c200);
    }
    &__highlight {
      border-bottom-color: #e06f4c;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      @include respond-max($breakpoint-desktop) {
        margin: rem(45px) 0 rem(19px) 0;
      }
      @include respond-min($breakpoint-desktop-large) {
        padding: 0 1.5rem calc(2vh + 1.5rem);
      }
      &:hover {
        border-left: 1px solid var(--sleep-red-c500);
        border-right: 1px solid var(--sleep-red-c500);
        border-bottom: rem(5px) solid var(--sleep-red-c500);
      }
    }
  }
}
